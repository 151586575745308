import React, { FC } from "react"
import styled from "@emotion/styled"
import { graphql, PageProps, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Grid, Link, Typography } from "@material-ui/core"
import Layout from "../components/layout"

const useMitgliedsantragImageSharpFixed = () => {
  const query = graphql`
    query MyQuery {
      file(relativePath: { eq: "img/Mitgliedsantrag-OGeMA-eV.jpg" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `
  return useStaticQuery(query).file.childImageSharp
}

const AntragStyled = styled.div`
  display: flex;
  justify-content: space-around;
`

const MitgliedschaftPage: FC<PageProps> = () => {
  const imageSharp = useMitgliedsantragImageSharpFixed()
  return (
    <Layout>
      <Typography variant="h4" paragraph>
        Fördermitgliedschaft
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <Typography paragraph>
            Treten Sie dem Förderverein der OGeMA als Fördermitglied bei und
            unterstützen Sie unsere Arbeit! Mit Ihrem Mitgliedsbeitrag von €30,-
            pro Jahr ermöglichen Sie uns die Förderung des musikalischen
            Nachwuchses und die Umsetzung vielfältiger gemeinnütziger Ziele.
          </Typography>
          <Typography paragraph>Unser Spendenkonto:</Typography>
          <Typography paragraph>
            OGeMA e.V.
            <br />
            Stadtsparkasse München
            <br />
            IBAN: DE69 7015 0000 0000 1316 23
            <br />
            BIC: SSKMDEMMXXX
          </Typography>
          <Typography paragraph>
            Steuerlich abzugsfähige Spendenbescheinigungen können auf Nachfrage
            ausgestellt werden.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <AntragStyled>
            <Link href="./Mitgliedsantrag-OGeMA-eV.pdf" download>
              <Img fixed={imageSharp.fixed} alt="Mitgliedsantrag" />
            </Link>
          </AntragStyled>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default MitgliedschaftPage
